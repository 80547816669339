
import { defineComponent, inject, ref, watch } from "vue";
import { ImageData } from "coho-ui";
import server from "../server";
import { Database } from "vuebase";
import { FeedItem, Product } from "../main";
import { CachePolicy } from "vuebase/src/storage";

export default defineComponent({
    name: "EditFeed",
    setup() {
        const database = inject(Database.InjectionKey);
        let feed = database
            ?.collection<FeedItem>("feed")
            .documents()
            .sort((a, b) => (b.data?.dateCreated.seconds ?? 0) - (a.data?.dateCreated.seconds ?? 0));
        let blankFeedItem = {
            image: undefined,
            categories: [],
            dateCreated:
                Database.firebase.firestore.FieldValue.serverTimestamp() as Database.firebase.firestore.Timestamp,
        } as FeedItem;
        let feedItem = ref<Database.Document<FeedItem>>();
        let products = database?.collection<Product>("products").documents();
        let categories = ref([] as string[]);

        watch(
            () => products,
            () => {
                setupCategories();
            },
            { immediate: true, deep: true },
        );

        function setupCategories(): void {
            categories.value = ["Home", ...(products?.map((product) => product.data?.name ?? "") ?? []), "Services"];
        }

        function uploadImage(file: File, progressCallback: (progress: number) => void): Promise<ImageData | void> {
            return server.uploadImage(
                file,
                "feed",
                progressCallback,
                CachePolicy.Day,
                { width: 1200, height: 1200 },
                100,
            );
        }

        function newItem(): void {
            const item = database?.collection<FeedItem>("feed").document();
            if (item) {
                item.data = { ...blankFeedItem };
            }

            feedItem.value = item;
        }

        function saveItem(): Promise<void> {
            if (!feedItem.value) {
                return Promise.reject();
            }

            return feedItem.value.save().then(() => {
                feedItem.value = undefined;
            });
        }

        function deleteItem(): Promise<void> {
            if (!feedItem.value) {
                return Promise.reject();
            }

            return feedItem.value.delete().then(() => {
                feedItem.value = undefined;
            });
        }

        return {
            feed,
            feedItem,
            categories,
            newItem,
            uploadImage,
            saveItem,
            deleteItem,
        };
    },
});
