<template>
    <c-panel class="white" v-if="feedItem">
        <div class="flex f-justify-center mt-5">
            <c-grid class="widths-100-all" style="max-width: 600px">
                <c-grid-item>
                    <c-image-picker
                        v-if="feedItem.data"
                        v-model="feedItem.data.image"
                        :initialImage="feedItem.exists ? feedItem.data.image : undefined"
                        :aspectRatio="1"
                        :upload="uploadImage"
                    ></c-image-picker>
                </c-grid-item>
                <c-grid-item>
                    <c-grid class="widths-100-all" v-if="feedItem.data">
                        <c-grid-item>
                            <c-select
                                label="Categories"
                                :multiple="true"
                                :options="categories"
                                v-model="feedItem.data.categories"
                            />
                        </c-grid-item>
                        <c-grid-item>
                            <c-textbox label="Artist" v-model="feedItem.data.artist" />
                        </c-grid-item>
                        <c-grid-item>
                            <c-textbox label="URL" v-model="feedItem.data.url" />
                        </c-grid-item>
                        <c-grid-item class="flex f-justify-end">
                            <c-button-group>
                                <c-button class="mt-5" @click="saveItem()">Save Post</c-button>
                                <c-button class="mt-5 red" @click="deleteItem()">Delete Post</c-button>
                            </c-button-group>
                        </c-grid-item>
                    </c-grid>
                </c-grid-item>
            </c-grid>
        </div>
    </c-panel>
    <c-panel v-if="feed" ref="feedElement">
        <c-button @click="newItem()" class="mb-5">
            <template #icon>
                <i class="fas fa-plus"></i>
            </template>
            New Post
        </c-button>
        <c-grid class="widths-20-all">
            <template v-for="(item, i) in feed" :key="i">
                <c-grid-item v-if="item.exists">
                    <c-card v-if="item.data?.image" @click="feedItem = item">
                        <c-image :image="item.data?.image" />
                    </c-card>
                </c-grid-item>
            </template>
        </c-grid>
    </c-panel>
</template>

<script lang="ts">
import { defineComponent, inject, ref, watch } from "vue";
import { ImageData } from "coho-ui";
import server from "../server";
import { Database } from "vuebase";
import { FeedItem, Product } from "../main";
import { CachePolicy } from "vuebase/src/storage";

export default defineComponent({
    name: "EditFeed",
    setup() {
        const database = inject(Database.InjectionKey);
        let feed = database
            ?.collection<FeedItem>("feed")
            .documents()
            .sort((a, b) => (b.data?.dateCreated.seconds ?? 0) - (a.data?.dateCreated.seconds ?? 0));
        let blankFeedItem = {
            image: undefined,
            categories: [],
            dateCreated:
                Database.firebase.firestore.FieldValue.serverTimestamp() as Database.firebase.firestore.Timestamp,
        } as FeedItem;
        let feedItem = ref<Database.Document<FeedItem>>();
        let products = database?.collection<Product>("products").documents();
        let categories = ref([] as string[]);

        watch(
            () => products,
            () => {
                setupCategories();
            },
            { immediate: true, deep: true },
        );

        function setupCategories(): void {
            categories.value = ["Home", ...(products?.map((product) => product.data?.name ?? "") ?? []), "Services"];
        }

        function uploadImage(file: File, progressCallback: (progress: number) => void): Promise<ImageData | void> {
            return server.uploadImage(
                file,
                "feed",
                progressCallback,
                CachePolicy.Day,
                { width: 1200, height: 1200 },
                100,
            );
        }

        function newItem(): void {
            const item = database?.collection<FeedItem>("feed").document();
            if (item) {
                item.data = { ...blankFeedItem };
            }

            feedItem.value = item;
        }

        function saveItem(): Promise<void> {
            if (!feedItem.value) {
                return Promise.reject();
            }

            return feedItem.value.save().then(() => {
                feedItem.value = undefined;
            });
        }

        function deleteItem(): Promise<void> {
            if (!feedItem.value) {
                return Promise.reject();
            }

            return feedItem.value.delete().then(() => {
                feedItem.value = undefined;
            });
        }

        return {
            feed,
            feedItem,
            categories,
            newItem,
            uploadImage,
            saveItem,
            deleteItem,
        };
    },
});
</script>
